<template>
  <div id="app">
    <AppHeader />
    <router-view />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader'

export default {
  name: 'App',

  components: { AppHeader }
}
</script>

<style lang="scss">
:root {
  --pageBg: #eceff1;
  --pageFg: #37474f;
  --pageBgSale: #{rgba(#4caf50, 0.15)};
  --keyline: #b0bec5;
  --buttonBg: #81c784;
  --buttonBgActive: #4caf50;
  --linkFg: #0d47a1;
  --linkFgActive: #1976d2;
  --linkFgVisited: #311b92;
  --accentDanger: #d32f2f;
  --accentDangerActive: #b71c1c;
  --accentSuccess: #00796b;
  --accentSuccessActive: #004d40;
  --accentDisabled: transparent;

  @media (prefers-color-scheme: dark) {
    --pageBg: #37474f;
    --pageFg: #eceff1;
    --keyline: #263238;
    --buttonBg: #388e3c;
    --buttonBgActive: #2e7d32;
    --linkFg: #42a5f5;
    --linkFgActive: #90caf9;
    --linkFgVisited: #b39ddb;
    --itemButtonOpacity: 0.85;
  }
}

*,
::before,
::after {
  box-sizing: border-box;
}

body {
  margin: 0.5rem;
  font-family: IBM Plex Sans, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 15px;
  background: var(--pageBg);
  color: var(--pageFg);
}

[id='app'] {
  margin: 0 auto 1rem;
  padding: 0;
  max-width: 100%;
  width: 35em;
}

a {
  color: var(--linkFg);
  text-decoration: none;

  &:hover,
  &:active {
    color: var(--linkFgActive);
  }

  &:visited {
    color: var(--linkFgVisited);
  }
}

.u-th {
  font-weight: 600;
}

.u-sr-only {
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  overflow: hidden;
}
</style>
