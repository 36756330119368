import axios from 'axios'

import { appUrl } from '@/filters/url'

const email = axios.create({
  baseURL: '/.netlify/functions/email/',
  method: 'post',
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 20000,
  responseType: 'json'
})

export const sendDealEmail = (name, id, price, discount) => {
  const discountText = discount ? `${discount} ` : ''

  return email({
    data: {
      name: 'Wishlist Alert',
      subject: `${name} on sale`,
      email: 'jdsteinbach+wishlist@gmail.com',
      message: `${name} is on sale for $${price}. ${discountText}Download it here: ${appUrl(
        id
      )}`
    }
  })
}

export const sendDeletionEmail = (name, id) => {
  return email({
    data: {
      name: 'Wishlist Deletion',
      subject: `${name} Deleted`,
      email: 'jdsteinbach+wishlist@gmail.com',
      message: `You just deleted ${name} from your wishlist. If that was unintentional, add it again from the app store: ${appUrl(
        id
      )}`
    }
  })
}
