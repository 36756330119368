<template>
  <AppList app-type="Switch" />
</template>

<script>
import AppList from '@/components/AppList.vue'

export default {
  name: 'Switch',

  components: {
    AppList
  }
}
</script>
