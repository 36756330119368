<template>
  <AppList app-type="iOS" />
</template>

<script>
import AppList from '@/components/AppList.vue'

export default {
  name: 'iOS',

  components: {
    AppList
  }
}
</script>
