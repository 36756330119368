<template>
  <div class="home">
    <template v-if="$auth.loading">
      <p>Loading&hellip;</p>
    </template>
    <template v-else>
      <p v-if="$auth.isAuthenticated">
        Welcome to the App Wishlist. Choose a category above to check for deals.
      </p>
      <div v-else>
        <p>
          This is a private app. Please log in if you're an authorized user.
        </p>

        <button class="button button--login" @click="login">Log In</button>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Home',

  methods: {
    login() {
      this.$auth.loginWithRedirect()
    }
  }
}
</script>

<style lang="scss">
.home {
  margin-top: 4rem;
  text-align: center;
}

.button--login {
  margin: 2rem auto;
  padding: 0 1rem;
  width: 35em;
  max-width: 100%;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border: 0;
  border-radius: 3px;
  font-weight: 600;
  font-family: inherit;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: var(--pageFg);
  background-color: var(--buttonBg);

  &:hover,
  &:active {
    background-color: var(--buttonBgActive);
  }
}
</style>
