import Vue from 'vue'
import Vuex from 'vuex'

import { getApps } from '@/services/AppService'

Vue.use(Vuex)

const modules = {}

const store = new Vuex.Store({
  modules,
  state: {
    apps: []
  },
  mutations: {
    SET_APPS(state, apps) {
      Vue.set(state, 'apps', apps)
    },

    UPDATE_APP(state, { id, data }) {
      state.apps.forEach(app => {
        if (app.app_id === id) {
          Object.keys(data).forEach(key => {
            if (data[key]) {
              Vue.set(app, key, data[key])
            }
          })
        }
      })
    },

    ADD_COMPLETION(state, record) {
      state.apps.forEach(app => {
        if (app.record === record) {
          Vue.set(app, 'completed', true)
        }
      })
    },

    CLEAR_COMPLETIONS(state) {
      state.apps.forEach(app => {
        Vue.set(app, 'completed', false)
      })
    },

    DELETE_APP(state, record) {
      const apps = state.apps.filter(app => {
        return app.record !== record
      })

      Vue.set(state, 'apps', apps)
    }
  },
  actions: {
    fetchApps({ commit }, appType) {
      commit('SET_APPS', [])

      return getApps(appType).then(r => {
        commit('CLEAR_COMPLETIONS')
        commit('SET_APPS', r.data)
      })
    },

    updateApp({ commit }, appData) {
      commit('UPDATE_APP', appData)
    }
  },
  getters: {
    completed: state => {
      return state.apps.filter(app => app.completed).length
    },

    total: state => {
      return state.apps.length
    }
  }
})

// Automatically run the `init` action for every module,
// if one exists.
for (const moduleName of Object.keys(modules)) {
  if (modules[moduleName].actions && modules[moduleName].actions.init) {
    store.dispatch(`${moduleName}/init`)
  }
}

export default store
