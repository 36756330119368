<template>
  <div>
    <template v-if="$auth.loading">
      <p>Loading&hellip;</p>
    </template>
    <template v-else>
      <template v-if="$auth.isAuthenticated">
        <div class="buttons">
          <button class="button button--logout" @click="logout">
            Log Out
          </button>
          <button v-if="completed < total" class="button" @click="retry">
            Retry Failed Apps
          </button>
        </div>
        <div class="progress" :style="loadingStyle"></div>
        <ul>
          <AppItem
            v-for="(a, i) in apps"
            :index="i"
            :key="a.app_id"
            :data-key="a.app_id"
            :app="a"
            :oldPrice="a.oldPrice"
            :price="a.price"
            :title="a.title"
            :image="a.image"
            :reload="a.reload"
            :app-type="appType"
          />
        </ul>
      </template>
      <p v-else>Return home and <router-link to="/">log in</router-link>.</p>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import { mapGetters, mapState } from 'vuex'

import AppItem from '@/components/AppItem.vue'

export default {
  components: {
    AppItem
  },

  props: {
    appType: {
      type: String,
      default: '',
      validator: value => ['iOS', 'PlayStation', 'Switch'].indexOf(value) > -1
    }
  },

  computed: {
    loadingStyle: function() {
      const currentTotal =
        this.total > this.completed ? this.total - this.completed : 0
      return `--completed:${currentTotal};--total:${this.total}`
    },
    ...mapState(['apps']),
    ...mapGetters({
      total: 'total',
      completed: 'completed'
    })
  },

  mounted() {
    store.dispatch('fetchApps', this.appType)
  },

  methods: {
    retry: function() {
      this.$emit('retry-failed')
    },

    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      })
    }
  }
}
</script>

<style lang="scss">
.buttons {
  margin: 0.5rem auto;
  max-width: 35em;
  display: grid;
  grid-template-columns: repeat(auto-fit, auto);
  grid-auto-flow: column;
  justify-content: space-between;
  column-gap: 0.5rem;
}

.button {
  padding: 0 1rem;
  height: 2rem;
  display: inline-flex;
  align-items: center;
  appearance: none;
  border: 0;
  border-radius: 3px;
  font-weight: 600;
  font-family: inherit;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #fff;
  background-color: var(--buttonBg);

  &:hover,
  &:active {
    background-color: var(--buttonBgActive);
  }
}

.button--logout {
  --buttonBg: var(--accentDanger);
  --buttonBgActive: var(--accentDangerActive);
}

p {
  text-align: center;
}

ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: left;
  border: 1px solid var(--keyline);
  border-bottom: 0;
}

.progress {
  overflow: hidden;
  border: 1px solid var(--keyline);
  border-bottom: 0;

  &::before {
    content: '';
    height: 0.25rem;
    display: block;
    background: var(--keyline);
    transition: transform 0.1s ease-out;
    transform: translateX(calc(-100% * var(--completed, 0) / var(--total)));
  }
}
</style>
