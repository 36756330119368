import Vue from 'vue'
import App from './App.vue'
import store from './store'
import Auth0Plugin from '@/plugins/auth0'
import monetize from '@/filters/monetize'
import { appUrl } from '@/filters/url'
import router from './router'

require('dotenv').config()

const { VUE_APP_AUTH0_DOMAIN, VUE_APP_AUTH0_CLIENT_ID } = process.env

Vue.config.productionTip = false

Vue.filter('monetize', monetize)
Vue.filter('appUrl', appUrl)

Vue.use(Auth0Plugin, {
  domain: VUE_APP_AUTH0_DOMAIN,
  clientId: VUE_APP_AUTH0_CLIENT_ID,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  response: 'token'
})

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
