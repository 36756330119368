import Vue from 'vue'
import Router from 'vue-router'
import iOS from '@/views/iOS.vue'
import PS from '@/views/PS.vue'
import Switch from '@/views/Switch.vue'
import Home from '@/views/Home.vue'
import NotFound from '@/views/NotFound.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/ios',
      name: 'ios',
      component: iOS
    },
    {
      path: '/ps',
      name: 'ps',
      component: PS
    },
    {
      path: '/switch',
      name: 'switch',
      component: Switch
    },
    {
      path: '*',
      name: '404',
      component: NotFound
    }
  ]
})
