const colors = {
  error: '#EF5350',
  warn: '#F57C00',
  info: '#009688'
}
const styledConsole = (string, action) =>
  // eslint-disable-next-line no-console
  console[action](
    `%c${string}`,
    `display:inline-block;padding:.25em .5em;color:${colors[action]};border:2px solid currentColor;border-radius:3px`
  )

export const error = string => styledConsole(string, 'error')
export const warn = string => styledConsole(string, 'warn')
export const log = string => styledConsole(string, 'info')
