import axios from 'axios'

import { today, ymd } from '@/utils/utils'
import { appUrl } from '@/filters/url'

require('dotenv').config()

const { VUE_APP_AIRTABLE_API_KEY, VUE_APP_AIRTABLE_BASE } = process.env

const airtable = axios.create({
  baseURL: `https://api.airtable.com/v0/${VUE_APP_AIRTABLE_BASE}/`,
  timeout: 20000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${VUE_APP_AIRTABLE_API_KEY}`
  },
  responseType: 'json'
})

const appData = axios.create({
  baseURL: '/.netlify/functions/',
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 20000,
  responseType: 'json'
})

export const getApps = appType => {
  return airtable.get(`${appType}/`, {
    transformResponse: data => {
      if (typeof data === 'string') {
        data = JSON.parse(data)
      }

      return data.records
        .map(a => {
          return {
            record: a.id,
            app_id: a.fields.ID,
            oldPrice: a.fields.Price,
            date: a.fields.Updated,
            title: a.fields.Title,
            completed: false,
            reload: 0
          }
        })
        .sort((a, b) => {
          if (!a.title || !b.title) return -1
          return a.title.toUpperCase() > b.title.toUpperCase() ? 1 : -1
        })
    }
  })
}

export const getAppInfo = (id, type) => {
  type = type || 'ios'

  return appData.post(`${type.toLowerCase()}/`, {
    url: appUrl(id)
  })
}

export const updateAppPrice = app => {
  const { record, price } = app

  return airtable.patch(`Apps/${record}`, {
    fields: {
      Updated: ymd(today()),
      Price: `${price}`
    }
  })
}

export const updateAppTitle = app => {
  const { record, title } = app

  return airtable.patch(`Apps/${record}`, {
    fields: { Title: title }
  })
}

export const deleteApp = id => airtable.delete(`Apps/${id}`)
