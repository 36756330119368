<template>
  <li class="app" :class="classObject" :data-sale="sale" :style="styledIndex">
    <div class="app-image">
      <div v-if="preload" class="app-img loading-indicator-wrapper">
        <div class="loading-indicator"></div>
      </div>
      <picture v-else class="app-img">
        <img class="app-img__image" :src="imgSrc" alt="" />
      </picture>
    </div>
    <div class="app-text">
      <a
        class="app-name u-th"
        :href="app.app_id | appUrl"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <b v-if="reload">RELOAD </b>
        {{ title }}
      </a>
      <div class="app-message">{{ message }}</div>
    </div>
    <div class="app-price-buttons">
      <div class="app-price app-price--previous">
        {{ oldPrice | monetize }}
      </div>
      <div class="app-price app-price--current">
        {{ newPrice | monetize }}
      </div>
      <div class="app-actions">
        <ActionButton
          v-if="sale"
          @click.prevent="emailApp"
          class="app-button app-button--email"
          :disabled="emailWaiting || emailSent"
        >
          <SentIcon v-if="emailSent" viewBox="0 0 16 16" aria-hidden />
          <WaitIcon v-else-if="emailWaiting" viewBox="0 0 16 16" aria-hidden />
          <EmailIcon v-else viewBox="0 0 16 16" aria-hidden />
          <span class="u-sr-only">Email</span>
        </ActionButton>
        <ActionButton
          v-if="failed"
          @click.prevent="loadApp"
          class="app-button app-button--refresh"
          theme="success"
        >
          <RefreshIcon viewBox="0 0 16 16" aria-hidden />
          <span class="u-sr-only">Refresh</span>
        </ActionButton>
        <ActionButton
          @click.prevent="deleteApp"
          class="app-button app-button--delete"
          theme="danger"
        >
          <DeleteIcon viewBox="0 0 16 16" aria-hidden />
          <span class="u-sr-only">Delete</span>
        </ActionButton>
      </div>
    </div>
  </li>
</template>

<script>
import store from '@/store'
import ActionButton from '@/components/ActionButton'
import DeleteIcon from 'bootstrap-icons/icons/trash-fill.svg'
import EmailIcon from 'bootstrap-icons/icons/envelope-fill.svg'
import RefreshIcon from 'bootstrap-icons/icons/arrow-repeat.svg'
import SentIcon from 'bootstrap-icons/icons/check-circle-fill.svg'
import WaitIcon from 'bootstrap-icons/icons/three-dots.svg'

import {
  deleteApp as removeApp,
  getAppInfo,
  updateAppTitle
} from '@/services/AppService.js'
import { sendDealEmail, sendDeletionEmail } from '@/services/EmailService.js'
import { log, error, warn } from '@/utils/console'

export default {
  components: {
    ActionButton,
    DeleteIcon,
    EmailIcon,
    RefreshIcon,
    SentIcon,
    WaitIcon
  },

  props: {
    app: Object,
    index: Number,
    price: {
      type: [Number, String],
      default: '-.--'
    },
    oldPrice: {
      type: [Number, String],
      default: '-.--'
    },
    title: String,
    image: String,
    reload: Number,
    appType: String
  },

  data: function() {
    return {
      previousPrice: false,
      id: '',
      message: '',
      notDeleted: true,
      stateClass: '',
      emailWaiting: false,
      emailSent: false
    }
  },

  computed: {
    classObject: function() {
      return {
        'on-sale': this.sale,
        'is-loading': this.preload,
        failed: this.failed
      }
    },
    failed: function() {
      return this.stateClass === 'failed'
    },
    fCurrentPrice: function() {
      return parseFloat(this.price)
    },
    fPreviousPrice: function() {
      return parseFloat(this.oldPrice || 0)
    },
    imgSrc: function() {
      return this.image
        ? this.image.replace(/\.j?pn?g\/.+$/gi, '.png/80x0w.jpg')
        : 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" height="24" width="24"%3E%3Cpath fill="rgba(76, 175, 80, 0.5)" d="M10.4 22.8H15v5h-4.6v-5zM21.1 1.6C19.9.6 17.6 0 14.3 0c-3.7 0-6.2.5-7.5 1.5-1.2 1-1.9 3-1.9 6v1h4.6v-1c0-1.4.3-2.4.9-2.9.6-.4 1.8-.7 3.6-.7 2 0 3.2.3 3.7.7.6.5.9 1.5.9 3 0 2-.8 3.2-2.3 3.8l-1.7.7c-2 .8-3 1.6-3.5 2.3-.5.7-.7 2.3-.7 4.5h4.7v-.6c0-1.4 1-2.5 3-3.4 2-.8 3.3-1.7 3.9-2.6.6-1 .9-2.5.9-4.8 0-2.9-.6-4.8-1.8-6z"/%3E%3C/svg%3E'
    },
    newPrice: function() {
      return this.price
    },
    preload: function() {
      return this.stateClass === 'preload'
    },
    sale: function() {
      return this.fCurrentPrice < this.fPreviousPrice
    },
    styledIndex: function() {
      return `order:${this.index + 2}`
    }
  },

  watch: {
    sale(newSale, oldSale) {
      if (newSale) {
        log(`Sale - ${this.title}`)

        this.message = `Save $${Math.round(
          this.fPreviousPrice - this.fCurrentPrice
        )} - ${Math.round(
          ((this.fPreviousPrice - this.fCurrentPrice) / this.fPreviousPrice) *
            10
        ) * 10}% off!`
      }
    },
    title(newTitle, oldTitle) {
      if (newTitle !== oldTitle) {
        log(`Changed title: ${oldTitle} > ${newTitle}`)
        updateAppTitle({
          record: this.app.record,
          title: this.title
        })
          .then(r => log(`App ${this.title} title updated`, r))
          .catch(e => {
            const msg = `App ${this.title} title update error`
            this.message = msg
            error(msg, e)
          })
      }
    }
  },

  methods: {
    deleteApp: function() {
      const app = this
      const record = app.app.record

      const confirmDelete = window.confirm(
        `Are you sure you want to remove ${app.title} from your wishlist?`
      )

      if (confirmDelete) {
        log('OK, you’re sure.')

        app.stateClass = 'preload'

        removeApp(record)
          .then(r => {
            warn(`${app.title} deleted`, r)

            sendDeletionEmail(app.title, app.app.app_id)
              .then(r => log(`${app.title} deletion email sent`, r))
              .catch(e => {
                app.message = 'Deletion email not sent'
                error(`${app.title} deletion email not sent`, e)
              })

            store.commit('DELETE_APP', app.app.record)
          })
          .catch(e => {
            app.message = 'Deletion error'
            error(`${app.title} delete error`, e)
          })
          .finally(() => {
            app.stateClass = ''
          })
      }
    },

    loadApp: function() {
      const app = this

      app.stateClass = 'preload'
      app.message = ''

      getAppInfo(app.app.app_id, this.appType)
        .then(r => {
          log(`Fetched ${app.title}`)

          if (r.data.title && r.data.price) {
            app.stateClass = 'success'

            store.dispatch('updateApp', {
              id: app.app.app_id,
              data: r.data
            })

            store.commit('ADD_COMPLETION', app.app.record)
          } else {
            app.message = 'Info not loaded'
            app.stateClass = 'failed'
            store.commit('ADD_COMPLETION', app.app.record)
            error(`${app.title} info not loaded`, r.data)
          }
        })
        .catch(e => {
          app.message = 'Info not loaded'
          app.stateClass = 'failed'
          error(`${app.title} info not loaded`, e)
        })
        .finally(() => {})
    },

    emailApp: function() {
      this.emailWaiting = true
      sendDealEmail(
        this.title,
        this.app.app_id,
        this.fCurrentPrice,
        this.message
      )
        .then(r => {
          this.emailSent = true
          this.message = 'Deal email sent'
          log(`${this.title} deal email sent`, r)
        })
        .catch(e => {
          this.message = 'Deal email not sent'
          error(`${this.title} deal email not sent`, e)
        })
        .finally(() => {
          this.emailWaiting = false
        })
    },

    conditionallyReload: function() {
      if (!this.app.completed) {
        this.loadApp()
      }
    }
  },

  mounted() {
    const app = this
    this.$parent.$on('retry-failed', function() {
      app.conditionallyReload()
    })
    this.loadApp()
  }
}
</script>

<style lang="scss">
.app {
  padding: 0.5rem;
  max-width: 100%;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  gap: 0.5rem;
  align-items: stretch;
  border-bottom: 1px solid var(--keyline);
}

.app-image {
  align-self: center;
}

.app-text {
  display: grid;
  grid-auto-rows: 1fr 1fr;
  justify-content: flex-start;
  align-self: center;
  gap: 0.125rem;
}

.app-price-buttons {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-template-rows: repeat(2, 1fr);
  gap: inherit;
}

.on-sale {
  order: -1 !important;
  font-weight: 600;
  background-color: var(--pageBgSale);
}

.failed {
  order: 0 !important;
  opacity: 0.8;
}

.app-img {
  width: 36px;
  height: 36px;
  display: block;
  position: relative;
  overflow: hidden;
  background: var(--keyline);
  border-radius: 25%;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid var(--keyline);
    border-radius: inherit;
    z-index: 1;
    opacity: 0.75;
  }
}

.app-img__image {
  width: 100%;
  height: 100%;
  object-fit: cover;

  &[src*="data:image"] {
    padding: .5rem;
  }
}

.app-text > * {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.app-price {
  flex: 0 0 auto;
  text-align: right;
  font-family: IBM Plex Mono, monospace;
  font-size: 0.85rem;
  white-space: nowrap;
}

.app-message {
  opacity: 0.8;
}

.app-actions {
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  gap: inherit;
  grid-column: 1 / -1;
}

.app-button--delete {
  .is-loading & {
    opacity: 0;
    pointer-events: none;
  }
}

.app-button--email {
  .is-loading & {
    opacity: 0;
    pointer-events: none;
  }
}

.loading-indicator {
  display: block;
}

.loading-indicator::before {
  content: '';
  width: 10px;
  height: 10px;
  display: inline-block;
  position: absolute;
  top: calc(50% - 7px);
  left: calc(50% - 7px);
  border: 2px solid #546e7a;
  border-left-color: var(--buttonFg);
  border-radius: 50%;
  animation: loading-indicator 2s linear infinite;
}

@keyframes loading-indicator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
