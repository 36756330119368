<template>
  <button v-bind="$attrs" v-on="$listeners" :class="`t-${theme}`" type="button">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ActionButton',

  props: {
    theme: {
      type: String,
      default: 'button',
      validator: val => ['button', 'danger', 'success']
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  margin: 0;
  padding: 0;
  height: 1.5rem;
  width: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
  border: 0;
  border-radius: 1rem;
  font-family: inherit;
  letter-spacing: 0.0625em;
  color: #fff;
  background-color: var(--buttonBg);
  font-weight: 600;
  text-transform: uppercase;
  transition: opacity 200ms;
  opacity: var(--itemButtonOpacity, 1);

  &:hover,
  &:active {
    background-color: var(--buttonBgActive);
  }

  &:disabled,
  &[disabled] {
    pointer-events: none;
    background-color: var(--accentDisabled);
  }

  svg {
    height: 50%;
    width: 50%;
    display: block;
    pointer-events: none;
  }
}

.t-danger {
  background-color: var(--accentDanger);

  &:hover,
  &:active {
    background-color: var(--accentDangerActive);
  }
}

.t-success {
  background-color: var(--accentSuccess);

  &:hover,
  &:active {
    background-color: var(--accentSuccessActive);
  }
}
</style>
